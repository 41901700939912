import { CustomCellRendererProps } from "@ag-grid-community/react";
import { HexType } from "@hex/common";
import React from "react";
import styled from "styled-components";

import { getOwnerName } from "../../../hex-list-2/hex-row/utils.js";
import { ProjectsTableSafeOrUnknownHexFragment } from "../../ProjectsTable.generated.js";
import { RowHeaderTableCellText, TableRowProjectTypeIcon } from "../styled.js";

type ReadonlyNameCellRendererOptions = Pick<
  ProjectsTableSafeOrUnknownHexFragment,
  "hexType" | "creator" | "owner"
>;

export interface ReadonlyNameCellRendererProps<DataType>
  extends CustomCellRendererProps<DataType> {
  mapDataToHex: (data: DataType) => ReadonlyNameCellRendererOptions;
}

const genericMemo: <T>(component: T) => T = React.memo;
export const ReadonlyNameCellRenderer = genericMemo(
  function ReadonlyNameCellRenderer<D>({
    data,
    mapDataToHex,
  }: ReadonlyNameCellRendererProps<D>) {
    if (data == null) {
      return null;
    }
    const hex = mapDataToHex(data);
    const ownerName = getOwnerName(hex);
    const text = `${hex.hexType === HexType.COMPONENT ? "Component" : "Project"} ${`owned by ${ownerName}`}`;
    return (
      <ReadonlyNameContainer>
        <TableRowProjectTypeIcon hexType={hex.hexType} isUnknownHex={true} />
        <RowHeaderTableCellText $isUnknownHex={true}>
          {text}
        </RowHeaderTableCellText>
      </ReadonlyNameContainer>
    );
  },
);

const ReadonlyNameContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${TableRowProjectTypeIcon} {
    padding-right: 8px;
  }
`;
